import { joinClassNames } from "@common/lib/util"
import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"

export function VerticalNavSkeleton({ width = "100%" }) {
  const boxStyles = {
    width,
    height: 20,
    borderRadius: 5,
  }

  const containerClasses = joinClassNames(
    "w-full h-full flex flex-col space-y-4 pl-3 overflow-y-scroll"
  )

  return (
    <div className={containerClasses}>
      {[...Array(10)].map((_, index) => (
        <BoxSkeleton key={index} styles={boxStyles} />
      ))}
    </div>
  )
}
