import BoxSkeleton from '@common/skeletons/skeletonComponents/boxSkeleton';

export default function OverallStatSkeleton() {
  const commonStyles = {
    height: 20
  };

  return (
    <div className="px-4 py-5 sm:p-5 overflow-hidden">
      <div className='space-y-8'>
        <BoxSkeleton
          styles={{
            ...commonStyles,
            width: "30%"
          }}
        />
        <div className="pt-2 mt-1 flex justify-between gap-2 items-baseline">
          <BoxSkeleton
            styles={{
              height: 40,
              width: "80%",
            }}
          />
          <BoxSkeleton
            styles={{
              height: 1,
              width: "100%",
            }}
          />
            <BoxSkeleton
            styles={{
              height: 40,
              width: "20%",
            }}
          />
        </div>
      </div>
    </div>
  );
}
