import { useState, useEffect, useRef } from "react"

interface UseKeyboardNavigationProps {
  itemsLength: number
  onEnter?: (index: number) => void
  onEscape?: () => void
}

export function useKeyboardNavigation({
  itemsLength,
  onEnter,
  onEscape,
}: UseKeyboardNavigationProps) {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null)
  const focusedRef = useRef(null)
  const overflowContainerRef = useRef(null)

  // Custom scroll handler for keyboard navigation
  const scrollIntoViewIfNeeded = (index: number) => {
    if (!focusedRef.current || !overflowContainerRef.current) return

    const element = focusedRef.current as HTMLElement

    const container = overflowContainerRef.current
    const elementRect = element.getBoundingClientRect()
    const containerRect = container.getBoundingClientRect()
    // Check if element is not fully visible
    if (
      elementRect.bottom > containerRect.bottom ||
      elementRect.top < containerRect.top
    ) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault()
        setFocusedIndex((prev) => {
          if (prev === null || prev === itemsLength - 1) return 0
          return prev + 1
        })
        break
      case "ArrowUp":
        e.preventDefault()
        setFocusedIndex((prev) => {
          if (prev === null || prev === 0) return itemsLength - 1
          return prev - 1
        })
        break
      case "Enter":
        if (focusedIndex !== null && onEnter) {
          e.preventDefault()
          onEnter(focusedIndex)
        }
        break
      case "Escape":
        e.preventDefault()
        if (onEscape) onEscape()
        break
      default:
        break
    }
  }

  useEffect(() => {
    // Reset focusedIndex when items change
    if (itemsLength === 0) setFocusedIndex(null)
  }, [itemsLength])

  useEffect(() => {
    scrollIntoViewIfNeeded(focusedIndex)
  }, [focusedIndex])

  return {
    focusedIndex,
    setFocusedIndex,
    handleKeyDown,
    focusedRef,
    overflowContainerRef,
  }
}
