import BoxSkeleton from '@common/skeletons/skeletonComponents/boxSkeleton';

export default function Sections() {

  const renderSection = (_, sectionIndex) => (
    <div key={sectionIndex}>
      <div className={"max-w-md w-full mb-2 "}>
        <BoxSkeleton
          styles={{
            width: "100%",
            height: 40,
            borderRadius: 5,
          }}
        />
      </div>

      <div className="flex flex-wrap gap-4">
        {[...Array(4)].map((_, itemIndex) => (
          <BoxSkeleton
            key={itemIndex}
            styles={{
              width: 260,
              height: 130,
              borderRadius: 10,
            }}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className={" w-full h-full flex flex-col space-y-10  "}>
      {[...Array(3)].map(renderSection)}
    </div>
  );
}
