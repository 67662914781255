const BarChartSkeleton = ({ repeat = 1 }) => {
  const heightArray = [
    72, 56, 72, 64, 80, 48, 36, 64, 56, 40, 32, 72, 28, 80, 64, 56, 40,
  ]

  return (
    <div
      aria-busy="true"
      aria-live="polite"
      role="status"
      className="h-full p-4 animate-pulse md:p-6 my-auto w-full overflow-hidden relative"
    >
      <div className="flex gap-2">
        <div className="h-5 w-5 bg-muted my-2"></div>
        <div className="h-5 w-32 bg-muted rounded-full my-2"></div>
      </div>
      <div className="flex gap-2">
        <div className="h-5 w-5 bg-muted  my-2"></div>
        <div className="h-5 w-48 bg-muted  rounded-full my-auto"></div>
      </div>
      <div className=" w-full h-full flex items-end gap-2">
        {[...Array(repeat)].flatMap((_, idx) =>
          heightArray.map((percentage, i) => (
            <div
              key={`${idx}-${i}`}
              style={{ height: `${percentage}%` }}
              className="flex w-full bg-muted mx-2"
            />
          ))
        )}
      </div>
    </div>
  )
}

export default BarChartSkeleton
