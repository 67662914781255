import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"

export default function ChannelsPageSkeleton() {
  const customTitle = {
    height: "30px",
    width: "250px",
  }
  const customSearchBox = {
    height: "30px",
    width: "600px",
  }
  const customDesc = {
    height: "20px",
    width: "150px",
  }
  const srcClasses = "h-20 w-64 mr-8 rounded-md"

  const renderChannelSkeletons = () => (
    <>
      <BoxSkeleton styles={customTitle} className="my-2" />
      <BoxSkeleton styles={customDesc} className="my-2" />
      <div className="flex my-2">
        {[...Array(4)].map((_, i) => (
          <BoxSkeleton key={i} className={srcClasses} />
        ))}
      </div>
    </>
  )

  return (
    <div className="px-5 py-2">
      <BoxSkeleton styles={customTitle} className="my-2" />
      <BoxSkeleton styles={customSearchBox} className="my-2" />
      {[...Array(2)].map((_, i) => (
        <div key={i}>{renderChannelSkeletons()}</div>
      ))}
    </div>
  )
}
