
export default function WriterSkeleton() {
  return (
    <div className=" flex justify-start max-w-fit ">
      <div className=" pl-4 pr-4 rounded-md animate-pulse bg-muted  flex justify-start">
        <div className=" m-2 text-sm font-semibold tracking-widest uppercase overflow-hidden whitespace-nowrap animate-animate_typing text-gray-700  border-gray-600 cursor-progress   ">
          Generating . . .
        </div>
      </div>
    </div>
  )
}
