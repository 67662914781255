import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"

const SearchSkeleton = () => {
  const commonStyles = {
    borderRadius: 6,
    height: 20,
    width: "100%",
  }

  const renderSearchItem = () => (
    <div className="pt-2 mt-1 flex flex-col justify-between gap-2 items-baseline border-t  p-2">
      <BoxSkeleton styles={{ ...commonStyles, width: "20%" }} />
      <BoxSkeleton styles={{ ...commonStyles, width: "80%" }} />
      <BoxSkeleton styles={{ ...commonStyles, width: "10%" }} />
    </div>
  )

  return (
    <div className="h-full overflow-hidden  justify-center gap-1 flex">
      <div
        className=" m-1 rounded-md  w-full overflow-y-hidden "
      >
        <div className="flex justify-center gap-2 px-10 py-4">
          {[...Array(4)].map((_, index) => (
            <BoxSkeleton key={index} styles={{ ...commonStyles, height: 60 }} />
          ))}
        </div>
        {[...Array(8)].map((_) => renderSearchItem())}
      </div>
    </div>
  )
}

export default SearchSkeleton
