import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"

export default function TopicSkeleton({ posColor, negColor }) {
  const titleStyle = { height: "20px", width: "60px", marginBottom: "10px" }
  const barStyle = { height: "10px", width: "100%", marginBottom: "20px" }
  const metricBoxStyle = {
    height: "60px",
    marginBottom: "10px",
    borderRadius: "0.5rem",
  }
  const impactScoreStyle = { height: "60px", width: "100%", borderRadius: "0.5rem" }

  return (
    <div className="p-2 w-full">
      <BoxSkeleton styles={titleStyle} />
      <div className="flex">
        <BoxSkeleton
          styles={{ ...barStyle, backgroundColor: posColor }}
          colorClass="bg-green-500"
        />
        <BoxSkeleton
          styles={{ ...barStyle, backgroundColor: negColor }}
          colorClass="bg-destructive"
        />
      </div>
      <div className="flex gap-2">
        <BoxSkeleton styles={metricBoxStyle} className="flex-1" />
        <BoxSkeleton styles={metricBoxStyle} className="flex-1" />
        <BoxSkeleton styles={metricBoxStyle} className="flex-1" />
      </div>
      <BoxSkeleton styles={impactScoreStyle} />
    </div>
  )
}
