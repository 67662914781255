import BoxSkeleton from '@common/skeletons/skeletonComponents/boxSkeleton';

export default function EntityGroupSkeleton() {
  const boxHeight = 38;
  const boxRadius = 5;
  const commonStyles = {
    height: boxHeight,
    borderRadius: boxRadius,
    maxWidth: "100%",
  };

  return (
    <div className="flex gap-1 py-1">
      <BoxSkeleton
        styles={{
          ...commonStyles,
          width: boxHeight,
        }}
      />
      <BoxSkeleton
        styles={{
          ...commonStyles,
          width: 200,
        }}
      />
    </div>
  );
}
