import BoxSkeleton from '@common/skeletons/skeletonComponents/boxSkeleton';

const SKELETON_COUNT = 3;
const BOX_WIDTH = 260;
const BOX_HEIGHT = 65;
const BORDER_RADIUS = 10;

export default function FlatCardsSkeleton() {
  return (
    <div className="my-4">
      <div className="flex flex-wrap gap-4">
        {[...Array(SKELETON_COUNT)].map((_, index) => (
          <BoxSkeleton
            key={index}
            styles={{
              width: BOX_WIDTH,
              height: BOX_HEIGHT,
              borderRadius: BORDER_RADIUS,
            }}
          />
        ))}
      </div>
    </div>
  );
}
