import BoxSkeleton from '@common/skeletons/skeletonComponents/boxSkeleton';

function ReportsPageSkeleton() {
  const customStyles = {
    title: {
      height: "30px",
      width: "250px",
    },
    searchBox: {
      height: "30px",
      width: "600px",
    },
    desc: {
      height: "20px",
      width: "150px",
    },
  }

  const reportClass = "h-72 w-64 mr-8 rounded-md"
  const bkmClasses = "h-20 w-64 mr-8 rounded-md"

  const renderBoxSkeletons = (className, count = 4) => (
    <div className="flex mb-4">
      {[...Array(count)].map((_, i) => (
        <BoxSkeleton key={i} className={className} />
      ))}
    </div>
  )

  return (
    <div className="mb-4">
      <BoxSkeleton styles={customStyles.title} className="mb-4" />
      <BoxSkeleton styles={customStyles.searchBox} className="mb-8" />

      {renderBoxSkeletons(reportClass)}

      <BoxSkeleton styles={customStyles.title} className="mb-4" />
      <BoxSkeleton styles={customStyles.desc} className="mb-4" />

      {renderBoxSkeletons(bkmClasses)}
    </div>
  )
}

export default ReportsPageSkeleton
