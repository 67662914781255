import BoxSkeleton from '@common/skeletons/skeletonComponents/boxSkeleton';

export default function ListSkeleton() {
  const LIST_ITEMS_COUNT = 8
  const TAGS_COUNT = 4

  const imgStyles = {
    height: 64,
    width: 64,
    borderRadius: "5px",
  }
  const nameStyles = {
    height: 20,
    width: "40%",
    borderRadius: "5px",
  }
  const tagStyles = {
    height: 30,
    width: "100%",
    borderRadius: "15px",
  }

  return (
    <div className="w-full h-full overflow-hidden">
      {[...Array(LIST_ITEMS_COUNT)].map((_, listItemIndex) => (
        <div className="flex gap-2 py-3" key={listItemIndex}>
          <BoxSkeleton styles={imgStyles} />
          <div className="w-full flex flex-col justify-between">
            <BoxSkeleton styles={nameStyles} />
            <div className="flex gap-1 justify-start">
              {[...Array(TAGS_COUNT)].map((_, tagIndex) => (
                <BoxSkeleton key={tagIndex} styles={tagStyles} />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
