import { joinClassNames } from "@common/lib/util"
import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"

export function HorizontalNavSkeleton({ width = "100%", colorClass }) {
  const boxStyles = {
    width,
    height: 20,
    borderRadius: 5,
  }

  const containerClasses = joinClassNames(
    "w-full h-full flex gap-4 justify-center overflow-y-scroll"
  )

  return (
    <div className={containerClasses}>
      {[...Array(10)].map((_, index) => (
        <BoxSkeleton key={index} styles={boxStyles} colorClass={colorClass}  />
      ))}
    </div>
  )
}
