import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"
import React from "react"
const SKELETON_COUNT = 5
const BOX_WIDTH = "100%"
const BOX_HEIGHT = 60
const BORDER_RADIUS = 10
export default function StatsSkeleton() {
  return (
    <div className="my-4">
      <div className="grid grid-cols-2 xl:grid-cols-3  gap-2 p-2 mb-10">
        {[...Array(SKELETON_COUNT)].map((_, index) => (
          <BoxSkeleton
            key={index}
            styles={{
              width: BOX_WIDTH,
              height: BOX_HEIGHT,
              borderRadius: BORDER_RADIUS,
            }}
          />
        ))}
      </div>
    </div>
  )
}
