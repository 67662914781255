import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"
import { Fragment } from "react"

const skeletonStyles = {
  title: { height: "20px", width: "300px" },
  reviewText: { height: "15px", width: "100%" },
  normalText: { height: "15px", width: "70px" },
  highlightedText: { height: "15px", width: "150px", backgroundColor: "#ffff78" },
  filterButton: { height: "75px", width: "100%", className: "rounded-md" },
}

export default function SidepanelSkeleton() {
  const renderReviewSkeletons = () => (
    <div className="border-b my-4">
      <BoxSkeleton styles={skeletonStyles.title} className="my-2" />
      <BoxSkeleton styles={skeletonStyles.reviewText} className="my-2" />
      <div className="flex flex-wrap my-2 gap-2">
        {[...Array(3)].map((_, i) => (
          <Fragment key={i}>
            <BoxSkeleton styles={skeletonStyles.highlightedText} className="my-1" />
            <BoxSkeleton styles={skeletonStyles.normalText} className="my-1" />
          </Fragment>
        ))}
      </div>
    </div>
  )

  return (
    <div className="px-5 py-2">
      <BoxSkeleton styles={skeletonStyles.title} className="my-10" />
      <div className="flex gap-2">
        {[...Array(3)].fill().map((_, i) => (
          <BoxSkeleton key={`filter-${i}`} styles={skeletonStyles.filterButton} />
        ))}
      </div>
      {[...Array(5)].fill().map((_, i) => renderReviewSkeletons())}
    </div>
  )
}
