import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"

export default function CellSkeleton() {
  return (
    <div>
      <BoxSkeleton className="h-2 w-1/3 m-1" />
      <BoxSkeleton className="h-2 w-1/2 m-1" />
      <BoxSkeleton className="h-2 w-full m-1" />
    </div>
  )
}
