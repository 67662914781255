import BoxSkeleton from '@common/skeletons/skeletonComponents/boxSkeleton';
import React from 'react';

const SKELETON_COUNT = 3;
const BOX_SIZE = 260;
const BORDER_RADIUS = 10;

export default function ThreeCardsSkeleton() {
  return (
    <div className="flex flex-wrap gap-4 w-full h-full overflow-hidden">
      {[...Array(SKELETON_COUNT)].map((_, index) => (
        <BoxSkeleton
          key={index}
          styles={{
            width: BOX_SIZE,
            height: BOX_SIZE,
            borderRadius: BORDER_RADIUS,
          }}
        />
      ))}
    </div>
  );
}

