import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"

export default function FormSkeleton({
  rows = 10,
  showHeader = true,
  fieldHeight = 40,
}) {
  const commonBoxStyles = {
    height: fieldHeight,
    borderRadius: 4,
  }

  return (
    <div className="w-full h-full flex flex-col space-y-4 overflow-y-hidden">
      {showHeader && (
        <div className="shrink-0 w-1/2 mx-auto pb-4">
          <BoxSkeleton styles={{ ...commonBoxStyles, width: "100%" }} />
        </div>
      )}
      {[...Array(rows)].map((_, index) => (
        <div className="space-y-2" key={index}>
          <BoxSkeleton styles={{ ...commonBoxStyles, width: "50%" }} />
          <BoxSkeleton styles={{ ...commonBoxStyles, width: "100%" }} />
        </div>
      ))}
    </div>
  )
}
