import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"

export default function ImageSkeleton() {
  return (
    <div className="flex flex-col space-y-3">
      <BoxSkeleton className="h-32 w-[428px] rounded-xl" />
      <div className="space-y-2">
        <BoxSkeleton className="h-4 w-[428px]" />
        <BoxSkeleton className="h-4 w-[428px]" />
      </div>
    </div>
  )
}
