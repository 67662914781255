import React, {
  ComponentPropsWithoutRef,
  createContext,
  ElementType,
  ReactNode,
} from "react"
import { type Placement as PopperPlacement } from "@popperjs/core"

import usePopperPosition from "./hooks/usePopperPosition"
import { isFunction, joinClassNames } from "@common/lib/util"
import { Portal } from "@radix-ui/react-portal"

const PopoverContext = createContext<any>(undefined)

export type PopoverProps = {
  children: ReactNode
  isClickType?: boolean
  position?: PopperPlacement
  strategy?: "fixed" | "absolute"
  portal?: boolean
  onVisibleChange?: (visibility: boolean) => void
  defaultVisible?: boolean
}

const bodyClasses =
  "bg-background w-max border rounded-md shadow-sm outline-none z-50"

export type PopoverButtonProps<ComponentType extends ElementType> = {
  as?: ComponentType
  children: ReactNode
  onClick?: (event: any) => void
  full?: boolean
} & ComponentPropsWithoutRef<ComponentType>

export const Popover = ({
  children,
  isClickType = true,
  position = "bottom-end",
  strategy = "fixed",
  onVisibleChange,
  portal,
  defaultVisible,
}: PopoverProps) => {
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    getArrowProps,
    triggerRef,
  } = usePopperPosition(
    position,
    isClickType,
    strategy,
    true,
    onVisibleChange,
    undefined,
    defaultVisible
  )

  function closePopover() {
    if (triggerRef) triggerRef.click()
  }

  return (
    <PopoverContext.Provider
      value={{
        bodyClasses,
        setTriggerRef,
        setTooltipRef,
        getTooltipProps,
        getArrowProps,
        visible,
        strategy,
        portal,
        closePopover,
      }}
    >
      {children}
    </PopoverContext.Provider>
  )
}

const PopoverButton = <ComponentType extends ElementType = "div">({
  children,
  onClick,
  full = true,
  as,
  ...rest
}: PopoverButtonProps<ComponentType>) => {
  const Component = as || "div"
  return (
    <PopoverContext.Consumer>
      {(context) =>
        context && (
          <Component
            ref={context.setTriggerRef}
            onClick={onClick}
            className={joinClassNames(
              "focus:outline-none  cursor-pointer flex",
              full ? "w-full" : "w-fit "
            )}
            {...rest}
          >
            {children}
          </Component>
        )
      }
    </PopoverContext.Consumer>
  )
}

const PopoverPanel = ({
  children,
}: {
  children:
    | ReactNode
    | (({ closePopover }: { closePopover: () => void }) => ReactNode)
}) => (
  <PopoverContext.Consumer>
    {({
      bodyClasses,
      setTooltipRef,
      getTooltipProps,
      visible,
      portal,
      closePopover,
    }) =>
      visible &&
      (portal ? (
        <Portal>
          <PopoverBody
            bodyClasses={bodyClasses}
            setTooltipRef={setTooltipRef}
            getTooltipProps={getTooltipProps}
          >
            {isFunction(children) ? children({ closePopover }) : children}
          </PopoverBody>
        </Portal>
      ) : (
        <PopoverBody
          bodyClasses={bodyClasses}
          setTooltipRef={setTooltipRef}
          getTooltipProps={getTooltipProps}
        >
          {isFunction(children) ? children({ closePopover }) : children}
        </PopoverBody>
      ))
    }
  </PopoverContext.Consumer>
)

Popover.Button = PopoverButton
Popover.Body = PopoverPanel

/**
 *
 * @private
 */
function PopoverBody({
  bodyClasses,
  setTooltipRef,
  getTooltipProps,
  children,
}: {
  bodyClasses: string
  setTooltipRef: any
  getTooltipProps: any
  children: ReactNode
}) {
  return (
    <div className={bodyClasses} ref={setTooltipRef} {...getTooltipProps()}>
      {children}
    </div>
  )
}
