import { getRandomRGBColor } from "@common/lib/util"
import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"
import { useEffect, useState } from "react"

export default function PaletteSkeleton() {
  const [colors, setColors] = useState([])

  useEffect(() => {
    const newColors = [...Array(10)].map(() => getRandomRGBColor())
    setColors(newColors)
  }, [])

  const titleSkeletonStyles = {
    width: 150,
    height: 25,
  }
  const colorBoxStyles = {
    width: 150,
    height: 40,
  }

  return (
    <div className="relative">
      <p className="text-right link-blue absolute -top-6 lg:-top-12 right-0 cursor-pointer">
        <BoxSkeleton styles={{ width: 125, height: 20 }} />
      </p>
      <div className="mx-5">
        <p className="text-gray-900 font-semibold dark:text-gray-300 pt-8 pb-3">
          <BoxSkeleton styles={titleSkeletonStyles} />
        </p>
        <div className="mx-5 grid grid-cols-2 lg:grid-cols-3 gap-4">
          {colors.map((color, i) => (
            <BoxSkeleton
              key={i}
              styles={{
                ...colorBoxStyles,
                backgroundColor: color,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
