import BoxSkeleton from "@common/skeletons/skeletonComponents/boxSkeleton"

export default function TableSkeleton({ rows = 30 }) {
  return (
    <div className="p-0 w-full h-full overflow-y-hidden">
      <div className="flex flex-col gap-0.5">
        {/* Table Header */}
        <BoxSkeleton
          styles={{
            width: "100%",
            height: 49,
          }}
        />

        {/* Table Rows */}
        {[...Array(rows)].map((_, rowIndex) => (
          <div className="flex gap-0.5" key={rowIndex}>
            {[...Array(4)].map((_, colIndex) => (
              <BoxSkeleton
                key={colIndex}
                styles={{
                  width: "100%",
                  height: 33,
                }}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
